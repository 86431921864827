/*
 * @Author: zhang·xiao
 * @Date: 2023-01-19 00:15:37
 * @LastEditors: xiaopang
 * @LastEditTime: 2023-12-14 14:42:53
 * @Description: 描述文件功能
 */
export const step1SchemaProps = {
  "schema": {
    "type": "object",
    "properties": {
      "name": {
        "title": "名称",
        "type": "string",
        "ui:options": {
          "placeholder": "输入名称"
        }
      },
      // "categories": {
      //   "title": "类目",
      //   "type": "string",
      //   "ui:widget": "SelectWidget",
      //   "ui:options": {
      //     "placeholder": "类目字典选择"
      //   },
      //   "enum": [],
      //   "enumNames": [],
      //   "fetch": {
      //     api: '/awx/step_catagories',
      //     type: 'get',
      //     needInit: true,
      //     transfromData: (data) => {
      //       return data?.results?.map(e => ({ label: e.name, value: e.catagory }));
      //     }
      //   }
      // },
      "application_id": {
        "title": "所属应用",
        "type": "string",
        "ui:widget": "SelectWidget",
        "ui:options": {
          "placeholder": "输入所属应用"
        },
        "fetch": {
          api: '/awx/apps?page_size=1000&page=1&kind=-1',
          type: 'get',
          needInit: true,
          transfromData: (data) => {
            return data?.results?.map(e => ({ label: e.name, value: e.id }));
          }
        }
      },
      "form_type": {
        "title": "表单类型",
        "type": "string",
        "ui:widget": "SelectWidget",
        "ui:options": {
          "placeholder": "表单类型选择"
        },
        default: '0',
        "enum": [
          "0",
          "1"
        ],
        "enumNames": [
          "动态表单",
          "自定义表单"
        ],
      },
      "tags": {
        "type": "array",
        "uniqueItems": true,
        "items": {
          "type": "string",
          "enum": [],
          "enumNames": [],
        },
        "ui:options": {
          "placeholder": "请选择或输入标签",
          "filterable": true,
          "allow-create": true,
          "default-first-option": true
        },
        "title": "Tag",
        "ui:widget": "SelectWidget"
      },
      "type": {
        "title": "类型",
        "type": "string",
        "ui:options": {
          "placeholder": "请输入类型"
        }
      },
      "op_type": {
        "title": "操作类型",
        "type": "string",
        "ui:widget": "SelectWidget",
        "ui:options": {
          "placeholder": "操作类型选择"
        },
        default: '0',
        "enum": [
          "0",
          "1",
          "2"
        ],
        "enumNames": [
          "操作",
          "触发",
          "鉴权"
        ],
      },
      "description": {
        "title": "描述",
        "type": "string",
        "ui:options": {
          "placeholder": "请输入描述文字",
          "type": "textarea"
        }
      },
      "module_version": {
        "title": "版本",
        "type": "string",
        "ui:options": {
          "placeholder": "请输入版本"
        }
      }
    },
    "required": [
      "name",
      // "categories",
      "application_id",
      "form_type"
      // "module_version"
    ],
    "ui:order": [
      "name",
      // "categories",
      "application_id",
      "form_type",
      "tags",
      "type",
      "op_type",
      "description",
      "module_version"
    ]
  },
  "uiSchema": {},
  "formFooter": {
    "show": false
  },
  "formProps": {
    "labelPosition": "right",
    "labelWidth": "120px",
    "labelSuffix": "："
  }
}

export const step3SchemaProps = {
  "schema": {
    "type": "object",
    "properties": {
      "template_orch": {
        "title": "模版设计",
        "type": "string",
        "ui:widget": "CodeEditWidget",
        "ui:options": {
          "placeholder": "请输入",
          "type": "textarea",
          "editorHeight": "60vh"
        }
      }
    },
    "required": [
      "template_orch",
    ],
    "ui:order": [
      "template_orch"
    ]
  },
  "uiSchema": {},
  "formFooter": {
    "show": false
  },
  "formProps": {
    "labelPosition": "right",
    "labelWidth": "120px",
    "labelSuffix": "："
  }
}